import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'




// AppRouter
import App from './App';


// Configuracion Firebase-React-Redux

import * as serviceWorker from "./serviceWorker";





const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};



ReactDOM.render(
  <React.StrictMode>

        <BrowserRouter>
          <App />
        </BrowserRouter>

  </React.StrictMode>,
  document.getElementById('root'));
serviceWorker.unregister();
