import React from "react";

// import login from './img/login.png';
import logo from '../img/logo-svg.svg';
import '../styles/main.css';
// import ReactPlayer from 'react-player';
// import videoTheseus from './video/theseus.mp4';


const Home = () => {

  return (
    
    <div> 
   
     
      <div className="gray">
       <section className="container-fluid">
           <div className="row">
               <div className="col-sm-12  header">
                   <img className="img-fluid mx-auto d-block wow fadeInLeft" src={logo} alt="" data-wow-delay=".2s" />
                   <div className="col-sm-12 text-center wow fadeInLeft" data-wow-delay=".8s">
                   {/* <h2>Tu oficina en <span style={{color: "rgb(244, 210, 26)"}}>un click</span></h2> */}
                   <h2><span className="soon">PRÓXIMAMENTE</span></h2>
               </div>
               </div>            
           </div>
       </section>
       </div>
       
                   
     
         
             
          </div>
  );
};

export default Home;