import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";


// CSS
import './components/styles/bootstrap/css/bootstrap.min.css';



import './components/styles/styles.scss';




import Home from "./components/Home/Home";



import NotFound from './components/404/Notfound'




function App() {


//   const Content = withRouter(props =>
    
//     props.location.pathname.includes ("/Admin") ?
//     <AppNavBlue /> : <AppNav /> 
//  );

//  const Content2 = 

//  withRouter(props =>
    
//   props.location.pathname.includes ("/Admin") ?
//   "" : <Inside /> 
// );
 

  return (
    <BrowserRouter>    
      <div>        

        <Switch>

         

          {/* COMPONENTES */}
          <Route exact path="/" render={() => <Home  path="/" />} />
             


          <Route component={NotFound} />
        </Switch>
        

      </div>
    </BrowserRouter>
  );
}


export default App;
